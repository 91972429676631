import React from 'react'
import { Tabs ,Space, Typography} from 'antd';
import process from "../../../assets/process.png"
const PersonIdea = () => {
    return (
        <>
            <div style={{paddingLeft:30,paddingRight:30}}>
                <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '人才理念',
                    children:  
                            <>
                                <h2 style={{textAlign:"center"}}>汇能售电人才观</h2>
                                <p>汇能售电成长的根基源自于人才，我们坚信员工是企业最珍贵的资产。公司致力于为员工提供空间广阔又有挑战性的工作，轻松又和谐的工作氛围，完善的培训机制，以及具有竞争力的薪酬福利，从而吸引和发展优秀人才为企业和社会创造价值。</p>
                                <hr />
                                <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                                    <img style={{width:"100%"}} src={process} alt="汇能售电招聘流程图" />
                                </div>
                                
                            </>
                        ,
                }]}/>
            </div>
        </>
    )
}

export default PersonIdea