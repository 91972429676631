import React from 'react'
import { Tabs,Image } from 'antd';
import BA from"../../../assets/BA.png"
import GS from"../../../assets/GS.png"

const OrgChart = () => {
    return (
        <>
            <div style={{paddingLeft:30,paddingRight:30}}>
                <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '企业资质',
                    children:  
                    <>  
                        <div style={{display:"flex",justifyContent:"center"}}>
                            <Image
                                width={400}
                                src={BA}
                            />
                            <Image
                                width={400}
                                src={GS}
                            />
                        </div>
                    </>
                    ,
                }]}/>
            </div>
        </>
    )
}

export default OrgChart