import React from 'react'
import PageHeader from '../../components/PageHeader'
import bg from "../../assets/hr-banner.jpg"
import {
    UsergroupAddOutlined,
    TeamOutlined
    } from '@ant-design/icons';
import { Menu } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
    function getItem(label, key, icon, children) {
        return {
        key,
        icon,
        children,
        label,
        };
    }
    const items = [
            getItem('人才理念', '1', <TeamOutlined />),
            getItem('人才招聘', '2', <UsergroupAddOutlined />)
        ];
const JoinUs = () => {
    const navigate = useNavigate();
    function menuChange({ key}){;
        switch(key){
            case '1':
                navigate("personidea");
                break;
            case '2':
                navigate("talent");
                break;
            default:
                break;
        }
    }
    return (
        <>
            <PageHeader pageName="人才招聘" bg={bg}/>
            <div style={{backgroundColor:"rgb(246,247,248)",paddingBottom:50,paddingTop:50}}>
                {/* 左侧菜单 */}
                <div className='container' style={{display:"flex",flexWrap:"wrap",width:"100%"}} >
                    <Menu
                        style={{width: 256,height:110,padding:10,borderRadius:5,marginRight:20,marginBottom:"20px"}}
                        defaultSelectedKeys={['1']}
                        items={items}
                        onClick={menuChange}
                    />
                    <div className='pb-5' style={{backgroundColor:"#fff",width:"100%",borderRadius:5,width:"70%",minWidth:"360px"}}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JoinUs