import React from 'react'
import { Tabs ,Space, Typography} from 'antd';
import since from"../../../assets/since.gif"
import logo from"../../../assets/company_logo.png"
import facade from"../../../assets/facade.png"
import office from"../../../assets/office.png"
const { Text, Link,Title, Paragraph } = Typography;

const CompanyProfile = () => {
    return (
        <>
            <div style={{paddingLeft:30,paddingRight:30}}>
                <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '公司简介',
                    children:  
                        <Space direction="vertical" style={{width:"100%"}}>
                            <div style={{backgroundImage:`url(${since})`,width:"100%", backgroundSize:"cover",marginBottom:50}}>
                                <img src={logo} alt="宁夏汇能售电有限公司" width={"100%"} style={{maxWidth:"400px"}} />
                            </div>
                            <Paragraph>
                                宁夏汇能售电有限公司成立于 2017 年 5 月 2 日，注册资金 21000 万，是宁夏发改委首批公示进行交易的售电公司之一。2018 年 10 月 6 日通过北京电力交易中心公示，推送到全国 27 个省份电力交易中心开展售电业务。
                            </Paragraph>
                            <Paragraph style={{display:"flex",justifyContent:"center"}}>
                                <img src={facade} alt="宁夏汇能售电有限公司" width={"100%"}  />
                            </Paragraph>
                            <Paragraph>
                                公司集售电、虚拟电厂、技术咨询、节能诊断、综合能源服务、配套综合节能、合同能源管理等为一体的新型电能服务公司，是电力市场化改革下的新业态、新模式。    
                            </Paragraph>
                            <Paragraph style={{display:"flex",justifyContent:"center"}}>
                                <img src={office} alt="宁夏汇能售电有限公司" width={"100%"}  />
                            </Paragraph>
                            <Paragraph>
                            现以宁夏电力市场为中心，向周边各省开展跨区跨省售电业务，已开展浙江、新疆、重庆、上海等省市的跨区跨省市场化交易、绿色电力交易。与各省市电力交易中心、发改委、直购电用户建立良好关系，为推进开展各省间交易打下良好基础    
                            </Paragraph>
                        </Space>,
                }]}/>
            </div>
        </>
    )
}

export default CompanyProfile