import React, { useState } from 'react'
import { Tabs,Pagination ,Avatar, List, Radio, Space,Typography ,Modal} from 'antd';
import companyGate from"../../../assets/companyGate.png"
import DateBox from '../../../components/DateBox';
const {  Text } = Typography;
const data = [
        {
            title: '2023 年我公司代理用户参与区内交易电量约 120 亿千瓦时',
            detail:"2023 年我公司代理用户参与区内交易电量约 120 亿千瓦时，完成区内自备替代交易电量 35.16亿千瓦时、区内自备拆除交易电量2.64亿千瓦时；宁夏外购新疆清洁能源电量 2亿千瓦时；宁夏外送湖重庆电量 0.4 亿千瓦时；2022 年区内绿电交易电量 3.04亿千瓦时；为用户争取到更优惠电价的同时，也为宁夏的电力体制改革推进尽一份绵薄之力。",
            img:companyGate,
            day:"02",
            year:"2024-04"
        },
        {
            title: '宁夏汇能售电有限公司成立于2017年5月，是自治区发改委首批公示的十家售电公司之一',
            detail:"宁夏汇能售电有限公司成立于2017年5月，是自治区发改委首批公示的十家售电公司之一。",
            img:null,
            day:"02",
            year:"2024-04"
        },
        {
            title: '2018年全区燃煤自备机组替代试点交易由我公司组织完成，',
            detail:"2018年全区燃煤自备机组替代试点交易由我公司组织完成，被替代方：宁夏紫金花纸业有限公司，宁夏启元药业有限公司,2018年我公司完成北京交易中心首次跨区跨省替代交易，四川水电替代宁夏燃煤自备机组交易，被替代方：宁夏英力特化工有限公司",
            img:null,
            day:"02",
            year:"2024-04"
        },
        {
            title: '2019年至2023年我公司先后完成',
            detail:"2019年至2023年我公司先后完成宁夏晟晏集团，平罗县滨河碳化硅，宁夏金煜元能源化学及宁夏金煜元资源再生等企业的燃煤自备机组替代交易；以及宁夏灵州电厂及宁夏太西洗煤厂的发电权转让交易",
            img:null,
            day:"02",
            year:"2024-04"
        },
    ];
const Company = () => {
    const [ellipsis, setEllipsis] = useState(true);
    // 弹窗展示数据
    const [modalDetail, setModalDetail] = useState({});
    // 控制对话框
    const [openModal, setOpenModal] = useState(false);
    const openNewsDetail = (news)=>{
        setModalDetail(news)
        setOpenModal(true)
    }
    return (
        <>
        <div style={{paddingLeft:30,paddingRight:30}}>
            <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '公司新闻',
                    children:  <>
                        <List
                            pagination={{
                                position:"bottom",
                                align:"center",
                            }}
                            dataSource={data}
                            renderItem={(item, index) => (
                            <List.Item onClick={()=>openNewsDetail(item)}>
                                <List.Item.Meta
                                avatar={item.img?<Avatar  size={85} shape='square' src={item.img} />:<DateBox day={item.day} year={item.year}/>}
                                title={<a onClick={()=>openNewsDetail(item)} >{item.title}</a>}
                                description={<Text
                                            style={ellipsis ? { width: 700 } : undefined}
                                            ellipsis={ellipsis ? { tooltip: '点击查看更多' } : false}
                                            type={"secondary    "}
                                            >
                                            {item.detail}
                                            </Text>}
                                />
                                
                            </List.Item>
                            )}
                        />
                        <Modal
                            title={modalDetail.title}
                            centered
                            open={openModal}
                            onOk={() => setOpenModal(false)}
                            onCancel={() => setOpenModal(false)}
                            width={1000} 
                            footer={null}
                            closeIcon={null}
                        >
                            <hr />
                            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                                <img className="img-fluid" src={modalDetail.img} alt="" />
                            </div>
                            <hr />
                            <p>{modalDetail.detail}</p>
                        </Modal>
                        
                    </>
                }]}/>
        </div>
        </>
    )
}
export default Company