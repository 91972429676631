import React from 'react'
import { Tabs} from 'antd';

const TeamIntroduce = () => {
    return (
        <>
            <div style={{paddingLeft:30,paddingRight:30}}>
                <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '团队介绍',
                    children:  
                    <>  
                        <p>
                            公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，不断的谋求新的发展，并积累了丰富的交易经验。公司人才结构合理，团队现拥有一名高级工程师，三名中级工程师、数位专业的交易员以及营销团队。
                        </p>
                        <p>
                            公司在已有成绩的基础上，不懈努力、开拓进取，在省内外电力交易市场占有了一席之地，并赢得了广大客户及相关主管部门的好评。
                        </p>
                        <p><strong>我们是一支专业的团队。</strong>我们的成员拥有多年电力市场交易的背景。</p>
                        <p><strong>我们是一支年轻的团队。</strong> 我们的平均年龄仅有35岁，充满了朝气和创新精神。</p>
                        <p><strong>我们是一支行动力强的团队。</strong>没有做不成的事情，只有做不成事情的人。</p>

                    </>
                    ,
                }]}/>
            </div>
        </>
    )
}

export default TeamIntroduce