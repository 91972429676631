import React from 'react'
import PageHeader from '../../components/PageHeader'
import { Card } from 'antd';
import companyGate from"../../assets/companyGate.png"
const { Meta } = Card;
const ConcatOur = () => {
    return (
        <>
            <PageHeader pageName="联系我们"/>
            <div className='container' style={{width:"100%", display:"flex",marginBottom:50,marginTop:50,flexWrap:"wrap",justifyContent:"space-around"}}>
                <Card
                        hoverable
                        style={{ width: "60vh",padding:10}}
                        styles={{body:{padding:0}}}
                        cover={<img alt="example" style={{height:"100%"}} src="https://restapi.amap.com/v3/staticmap?location=106.305366,38.473656&zoom=15&size=700*650&&markers=-1,https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png,0:106.305366,38.473656&key=84c2ab28def5937118e64ad06194c6c1" />}
                    >
                </Card>
                <div>
                <Card
                    hoverable
                    style={{
                    maxWidth:"50vh",padding:10
                    }}
                    cover={<img  alt="宁夏汇能售电有限公司" src={companyGate} />}
                >
                    <h4>宁夏汇能售电有限公司</h4>
                    <p><i className="fa fa-map-marker-alt me-3"/><strong>地址：</strong>宁夏回族自治区银川市兴庆区晟芯大厦</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><strong>电话：</strong>0951-8966796</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><strong>手机：</strong>15769696371</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i><strong>邮箱：</strong>hnsd6796@126.com</p>
                </Card>
                </div>
            </div>
        </>
    )
}
export default ConcatOur