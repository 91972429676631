import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from"../../assets/company_logo.png"
const Header = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top ">
                <div style={{marginLeft: 'auto', marginRight: 'auto', display: 'flex',flexWrap:'wrap'}}>
                <NavLink className="nav-item nav-link navbar-brand d-flex align-items-center px-4 px-lg-5" to='/home'>
                    <h2 className="m-0 text-primary"><img src={logo} width="200vh"  alt="宁夏汇能售电有限公司"/></h2>
                </NavLink>
                    <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav  p-4 p-lg-0">
                            <NavLink className="nav-item nav-link" to='/home'>首页</NavLink>
                            <NavLink className="nav-item nav-link" to='/about/companyprofile'>公司概况</NavLink>
                            <NavLink className="nav-item nav-link" to='/businessintroduction'>业务介绍</NavLink>
                            <NavLink className="nav-item nav-link" to='/news/company'>新闻中心</NavLink>
                            <NavLink className="nav-item nav-link" to='/joinus/personidea'>人才招聘</NavLink>
                            <NavLink className="nav-item nav-link" to='/concatour'>联系我们</NavLink>
                            <NavLink className="nav-item nav-link" to='http://8.130.89.23:83/'>汇能交易辅助系统</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header