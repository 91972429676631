import React from 'react'
import Business from '../../components/Business'
import PageHeader from"../../components/PageHeader"
import newsBanner2 from "../../assets/news-banner2.jpg"
import newsBanner3 from "../../assets/csd-home-pc.jpg"

const BusinessIntroduction= () => {
    return (
        <>
            <PageHeader pageName="业务介绍" bg={newsBanner3}/>
            <Business/>
        </>
    )
}

export default BusinessIntroduction
