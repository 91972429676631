import React, { useState } from 'react'
import { Tabs,Avatar, List,Typography ,Modal} from 'antd';
import DateBox from '../../../components/DateBox';
import image1 from "../../../assets/6384730938992153061885161.png"
import image2 from "../../../assets/6384697331445772987116983.png"
import image3 from "../../../assets/6384729790492240576131076.jpg"
const { Text } = Typography;
const data = [
    {
        title: '全国各地2024年4月电网企业代理购电价格公布',
        detail:"近日，全国各地2024年4月电网企业代理购电价格陆续公布。北极星售电网汇总了2023年7月-2024年4月各地电网企业代理购电价格，如下图所示：以下为2024年4月各地电网企业代理购电价格表，顺序依次为：江苏、安徽、广东、山东、山西、北京、河北、冀北、浙江、上海、重庆、四川、黑龙江、辽宁、吉林、内蒙古东部、江西、湖北、湖南、青海、宁夏、陕西、天津、甘肃、新疆、广西、海南、贵州、云南、深圳、福建。（河南、内蒙古电力集团暂未公布）江苏",
        img:image1,
        day:"02",
        year:"2024-04"
    },
    {
        title: '国内首个电力爬坡辅助服务市场规则在山东出台应用',
        detail:"日前，国家能源局山东监管办公室印发《山东电力爬坡辅助服务市场交易规则（试行）》，这是国内首个电力爬坡辅助服务市场规则。随着新型电力系统建设进程加快，山东新能源装机规模快速扩大，截至2023年底，新能源装机容量达8228.8万千瓦，已超过直调煤电装机，占全省总装机比例的45.3%，位列全国省级电网第一，新能源高比例接入和“多光少风”的装机结构，使电网调度负荷的波动性和不确定性增加，对系统的灵活爬坡能力提出了更高要求。对此，公司积极开展市场调研与规则研讨，完成爬坡辅助服务交易系统建设，为主管部门提供了有力支撑。爬坡辅助服务是指为应对可再生能源发电波动等不确定因素带来的系统净负荷短时大幅变化，具备较强负荷调节速率的并网主体根据调度指令调整出力，以维持系统功率平衡所提供的服务。《规则》明确爬坡辅助服务市场采用“日前申报、实时出清”的方式与实时电能量联合出清，以爬坡辅助服务交易机制激励火电机组、新型经营主体（包括独立新型储能电站）等主动参与电网调节。自3月1日启动结算试运行以来，各经营主体积极参与，系统爬坡响应能力大幅提升，有效应对了新能源高比例接入带来的系统灵活爬坡能力不足问题，为电网安全、优质、经济运行提供了强效保障。未来，国网山东电力将协助主管部门动态分析爬坡辅助服务运行效果，持续优化完善电力市场机制，优化业务流程，保障爬坡辅助服务市场有效运行。",
        img:null,
        day:"01",
        year:"2024-04"
    },
    {
        title: '宁夏电力交易中心1家售电公司注册公示结果',
        detail:"近日，宁夏电力交易中心发布关于售电公司注册公示结果的公告。详情如下：",
        img:image2,
        day:"02",
        year:"2024-04"
    },
    {
        title: '北京电力交易中心2024年2月新能源省间市场化交易情况',
        detail:"2月，北京电力交易中心组织新能源省间交易完成电量130亿千瓦时。1～2月，新能源省间交易累计完成电量288亿千瓦时,2月，通过天中、祁韶、灵绍、鲁固、高岭等跨区通道组织西北、东北地区新能源送华北、华东、华中电量95亿千瓦时，输电通道新能源占比38.7%。1～2月，通过天中、祁韶、灵绍、鲁固、高岭等跨区通道组织西北、东北地区新能源送华北、华东、华中累计电量200亿千瓦时，输电通道新能源占比32.1%",
        img:image3,
        day:"02",
        year:"2024-04"
    },
];
const IndustryTrends = () => {
    const [modalDetail, setModalDetail] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [ellipsis, setEllipsis] = useState(true);
    const openNewsDetail = (news)=>{
        setModalDetail(news)
        setOpenModal(true)
    }
    return (
        <>
        <div style={{paddingLeft:30,paddingRight:30}}>
            <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '行业动态',
                    children:  <>
                        <List
                            pagination={{
                                position:"bottom",
                                align:"center",
                            }}
                            dataSource={data}
                            renderItem={(item, index) => (
                            <List.Item onClick={()=>openNewsDetail(item)}>
                                <List.Item.Meta
                                avatar={item.img?<Avatar  size={85} shape='square' src={item.img} />:<DateBox day={item.day} year={item.year}/>}
                                title={<a onClick={()=>openNewsDetail(item)} >{item.title}</a>}
                                description={<Text
                                            style={ellipsis ? { width: 700 } : undefined}
                                            ellipsis={ellipsis ? { tooltip: '点击查看更多' } : false}
                                            type={"secondary    "}
                                            >
                                            {item.detail}
                                            </Text>}
                                />
                                
                            </List.Item>
                            )}
                        />
                        <Modal
                            title={modalDetail.title}
                            centered
                            open={openModal}
                            onOk={() => setOpenModal(false)}
                            onCancel={() => setOpenModal(false)}
                            width={1000} 
                            footer={null}
                            closeIcon={null}
                        >
                            <hr />
                            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                                <img className="img-fluid" src={modalDetail.img} alt="" />
                            </div>
                            <hr />
                            <p>{modalDetail.detail}</p>
                        </Modal>
                        
                    </>
                }]}/>
        </div>
        </>
    )
}

export default IndustryTrends