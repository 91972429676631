import React from 'react'
import PageHeader from '../../components/PageHeader'
import serviceBG from "../../assets/service_bg.png"
import {
    TeamOutlined,
    FileSearchOutlined,
    HomeOutlined,
    FormOutlined
    } from '@ant-design/icons';
    import { Menu } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
    function getItem(label, key, icon, children) {
        return {
        key,
        icon,
        children,
        label,
        };
    }
    const items = [
            getItem('企业简介', '1', <HomeOutlined />),
            getItem('团队介绍', '3', <TeamOutlined />),
            getItem('大事记录', '4', <FormOutlined />),
            getItem('企业资质', '2', <FileSearchOutlined />),
        ];
    
const About = () => {
    const navigate = useNavigate();
    function menuChange({ key}){;
        switch(key){
            case '1':
                navigate("companyprofile");
                break;
            case '2':
                navigate("orgchart");
                break;
            case '3':
                navigate("teamintroduce");
                break;
            case '4':
                navigate("eventrecord");
                break;
        }
    }
    return (
        <>
            <PageHeader pageName="公司概况" bg={serviceBG}/>
            <div style={{backgroundColor:"rgb(246,247,248)",paddingBottom:50,paddingTop:50}}>
                {/* 左侧菜单 */}
                <div className='container' style={{display:"flex",flexWrap:"wrap",width:"100%"}} >
                    <Menu
                        style={{width: 256,height:200,padding:10,borderRadius:5,marginRight:20,marginBottom:"20px"}}
                        defaultSelectedKeys={['1']}
                        items={items}
                        onClick={menuChange}
                    />
                    <div className='pb-5' style={{backgroundColor:"#fff",borderRadius:5,width:"70%",minWidth:"360px"}}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default About