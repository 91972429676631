import React from 'react'
import { NavLink } from 'react-router-dom'
import bei_an from "../../assets/bei_an.png"
const Footer = () => {
    return (
        <>
            <div className="container-fluid bg-dark text-body footer  pt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-7">
                        <div className="col-lg-3 col-md-7" style={{marginBottom:"20px"}}>
                            <h5 className="text-white mb-4">地址</h5>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>宁夏回族自治区银川市兴庆区晟芯大厦</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>0951-8966796</p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i>hnsd6796@126.com</p>
                            {/* <div className="d-flex pt-2">
                                <a className="btn btn-square btn-outline-light btn-social"  href="/#" onClick={(e) => e.preventDefault()}><i className="fab fa-qq"></i></a>
                                <a className="btn btn-square btn-outline-light btn-social" href="/#" onClick={(e) => e.preventDefault()}><i className="fab fa-weixin"></i></a>
                                <a className="btn btn-square btn-outline-light btn-social" href="/#" onClick={(e) => e.preventDefault()}><i className="fab fa-weibo"></i></a>
                                <a className="btn btn-square btn-outline-light btn-social"href="/#" onClick={(e) => e.preventDefault()}><i className="fab fa-microsoft"></i></a>
                            </div> */}
                        </div>
                        <div className="col-lg-5 col-md-6">
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-4">快速跳转</h5>
                            <NavLink className="btn btn-link" to='/home'>首页</NavLink>
                            <NavLink className="btn btn-link" to='/about/companyprofile'>公司概况</NavLink>
                            <NavLink className="btn btn-link" to='/businessintroduction'>业务介绍</NavLink>
                            <NavLink className="btn btn-link" to='/news/company'>新闻中心</NavLink>
                            <NavLink className="btn btn-link" to='/joinus/personidea'>人才招聘</NavLink>
                            <NavLink className="btn btn-link" to='/concatour'>联系我们</NavLink>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a href="/#" onClick={(e) => e.preventDefault()}>宁夏汇能售电有限公司</a> &copy;All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                {/* Designed By <a href="https://htmlcodex.com">HTML Codex</a> */}
                                
                                <img src={bei_an} alt=""  style={{marginRight:"10px",position:"relative",top:"-3px"}}/>
                                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=64010402001295" rel="noreferrer" target="_blank">宁公网安备64010402001295 </a>
                                &nbsp;&nbsp;<a href="https://beian.miit.gov.cn"> 宁ICP备2024003731号-1  </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer