import React from 'react'

const Service = () => {
    return (
        <>
            {/* 公司服务数据 */}
            <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="btn-lg-square bg-primary rounded-circle me-3">
                                        <i className="fa fa-check text-white"></i>
                                    </div>
                                    <h1 className="mb-0" data-toggle="counter-up">8000000</h1><span style={{color:"#333",fontSize:"16px"}}>MW·h</span>
                                </div>
                                <h5 className="mb-3">交易电量</h5>
                                <span>为用户争取到更优惠电价的同时，为宁夏的电力体制改革推进尽一份绵薄之力</span>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="btn-lg-square bg-primary rounded-circle me-3">
                                        <i className="fa fa-award text-white"></i>
                                    </div>
                                    <h1 className="mb-0" data-toggle="counter-up">2000000</h1><span style={{color:"#333"}}>MW·h</span>
                                </div>
                                <h5 className="mb-3">外送电交易</h5>
                                <span>为外省用户外送电量，降低用户电力成本</span>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="btn-lg-square bg-primary rounded-circle me-3">
                                        <i className="fa fa-users text-white"></i>
                                    </div>
                                    <h1 className="mb-0" data-toggle="counter-up">80</h1><h3>+</h3><span style={{color:"#333"}}></span>
                                </div>
                                <h5 className="mb-3">服务省内客户</h5>
                                <span>电力交易专家一对一定制化服务，力争让每一位客户100%满意！</span>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Service