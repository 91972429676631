import React, { useState } from 'react'
import { Card, Flex } from 'antd';
import shengYan from "../../assets/01b552a50e0b724c3ff4f88edf5f01b.png"
import bigHe from "../../assets/2cb535264a45cb093cc7d0092c9ad4f.png"
import jianFa from "../../assets/17c8842cf8ec20dfc4ea44e3ac3d554.jpg"
import qiYuan from "../../assets/3376656d05d35e471cecb3f14b837bb.jpg"
import longDing from "../../assets/c30dc242427d53ee63370918d0b6a6c.jpg"
import fengHuang from "../../assets/fengHuang-logo.png"
import nanwang from "../../assets/nanwang.png"
import longJi from "../../assets/long-ji-logo.png"

const Team = () => {
    const [client,setClient] = useState([
                                            {
                                                id:1,
                                                logo:nanwang
                                            },
                                            {
                                                id:2,
                                                logo:bigHe
                                            },
                                            {
                                                id:3,
                                                logo:jianFa
                                            },
                                            {
                                                id:4,
                                                logo:qiYuan
                                            },
                                            {
                                                id:5,
                                                logo:longDing
                                            },
                                            {
                                                id:6,
                                                logo:fengHuang
                                            },
                                            {
                                                id:7,
                                                logo:shengYan
                                            },
                                            {
                                                id:8,
                                                logo:longJi
                                            },
                                        ])
    return (
        <>
            <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                            <h6 className="text-primary">精选客户</h6>
                            <h1 className="mb-4">领先的技术，助您领跑行业</h1>
                        </div>
                        <div style={{display:"flex",width:"100%", justifyContent:"space-around",flexWrap:"wrap"}}>
                            
                            
                            {
                                client.map((cur)=>{
                                    return <>
                                                <Card
                                                    key={cur.id}
                                                    hoverable
                                                    style={{ width: 280,height:200, padding:10,margin:10,display:"flex",justifyContent:"center",alignItems:"center",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                                    styles={{body:{padding:0}}}
                                                    cover={<img alt="example" style={{height:200}} src={cur.logo} />} 
                                                >
                                                </Card>
                                            </>
                                })
                            }
                        </div>
                        
                    </div>
                </div>
        </>
    )
}

export default Team