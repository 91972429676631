import React from 'react'
import {Typography } from 'antd';
import xianHuo from '../../assets/xian_huo.png'
import xuNiDianChang from '../../assets/xu_ni_dian_chang.png'
const {  Text } = Typography;
const BusinessDetail = () => {
    return (
        <>
            <div className="container-xxl py-5" style={{padding:0}}>
                    <div className="container" style={{padding:0}} >
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                            <h6 className="text-primary">我们的发展方向</h6>
                            <h1 className="mb-4">查看我们最新项目</h1>
                        </div>
                        <div className="portfolio-container wow fadeInUp" data-wow-delay="0.5s" style={{display:"flex",justifyContent:"space-between",width:"100%",flexWrap: "nowrap",margin:0}}>
                            <div className="col-lg-4 col-md-6 portfolio-item first">
                                <div className="portfolio-img rounded overflow-hidden">
                                    <img className="img-fluid" src={xianHuo} alt=""/>
                                    <div className="portfolio-btn">
                                        <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={xianHuo} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <p className="text-primary mb-0">电力现货交易</p>
                                    <hr className="text-primary w-25 my-2" />
                                    <h5 className="lh-base">
                                        <Text
                                            style={ {width: 300 } }
                                            ellipsis={{ tooltip: '依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。' }}
                                            type={"secondary"}
                                            >
                                            依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。
                                        </Text>
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 portfolio-item first">
                                <div className="portfolio-img rounded overflow-hidden">
                                    <img className="img-fluid" src={xuNiDianChang} alt=""/>
                                    <div className="portfolio-btn">
                                        <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={xuNiDianChang} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <p className="text-primary mb-0">虚拟电厂</p>
                                    <hr className="text-primary w-25 my-2" />
                                    <h5 className="lh-base">
                                        <Text
                                            style={ {width: 300 } }
                                            ellipsis={{ tooltip: '依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。' }}
                                            type={"secondary"}
                                            >
                                            依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。
                                        </Text>
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 portfolio-item third">
                                <div className="portfolio-img rounded overflow-hidden">
                                    <img className="img-fluid" src="img/img-600x400-6.jpg" alt=""/>
                                    <div className="portfolio-btn">
                                        <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-6.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <p className="text-primary mb-0">光伏和储能业务</p>
                                    <hr className="text-primary w-25 my-2" />
                                    <h5 className="lh-base">
                                        <Text
                                            style={ {width: 300 } }
                                            ellipsis={{ tooltip: '2021 年-2023 年自治区政府将会大力支持光伏和储能业务，我公司将积极与新能源投资企业和金融企业携手为电厂、用户提供分布式能源、储能等项目的投资建设以及设备融资租赁服务' }}
                                            type={"secondary"}
                                            >
                                            2021 年-2023 年自治区政府将会大力支持光伏和储能业务，我公司将积极与新能源投资企业和金融企业携手为电厂、用户提供分布式能源、储能等项目的投资建设以及设备融资租赁服务
                                        </Text>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row g-1 portfolio-container wow fadeInUp" data-wow-delay="0.5s" style={{flexWrap: "nowrap",margin:0}}>
                            <div class="col-lg-4 col-md-6 portfolio-item first">
                                <div class="portfolio-img rounded overflow-hidden">
                                    <img class="img-fluid" src={xianHuo} alt=""/>
                                    <div class="portfolio-btn">
                                        <a class="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={xianHuo} data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <p class="text-primary mb-0">电力现货交易</p>
                                    <hr class="text-primary w-25 my-2" />
                                    <h5 class="lh-base">
                                        <Text
                                            style={ {width: 300 } }
                                            ellipsis={{ tooltip: '依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。' }}
                                            type={"secondary"}
                                            >
                                            依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。
                                        </Text>
                                    </h5>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 portfolio-item first">
                                <div class="portfolio-img rounded overflow-hidden">
                                    <img class="img-fluid" src={xuNiDianChang} alt=""/>
                                    <div class="portfolio-btn">
                                        <a class="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={xuNiDianChang} data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <p class="text-primary mb-0">虚拟电厂</p>
                                    <hr class="text-primary w-25 my-2" />
                                    <h5 class="lh-base">
                                        <Text
                                            style={ {width: 300 } }
                                            ellipsis={{ tooltip: '依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。' }}
                                            type={"secondary"}
                                            >
                                            依托自身技术优势，公司自主研发电力交易综合性辅助服务平台，全方位以“现货交易辅助服务平台、虚拟电厂智慧平台、报价预测辅助服务系统”等信息化技术，为用户提供安全可靠、创新高效的电力市场辅助服务。为市场主体解决信息不对称、信息寻源难、交易不便捷等问题。
                                        </Text>
                                    </h5>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 portfolio-item third">
                                <div class="portfolio-img rounded overflow-hidden">
                                    <img class="img-fluid" src="img/img-600x400-6.jpg" alt=""/>
                                    <div class="portfolio-btn">
                                        <a class="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="img/img-600x400-6.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <p class="text-primary mb-0">光伏和储能业务</p>
                                    <hr class="text-primary w-25 my-2" />
                                    <h5 class="lh-base">
                                        <Text
                                            style={ {width: 300 } }
                                            ellipsis={{ tooltip: '2021 年-2023 年自治区政府将会大力支持光伏和储能业务，我公司将积极与新能源投资企业和金融企业携手为电厂、用户提供分布式能源、储能等项目的投资建设以及设备融资租赁服务' }}
                                            type={"secondary"}
                                            >
                                            2021 年-2023 年自治区政府将会大力支持光伏和储能业务，我公司将积极与新能源投资企业和金融企业携手为电厂、用户提供分布式能源、储能等项目的投资建设以及设备融资租赁服务
                                        </Text>
                                    </h5>
                                </div>
                            </div> 

                        </div>*/}
                    </div>
                </div>
        </>
    )
}

export default BusinessDetail