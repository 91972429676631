import React from 'react'
const PageHeader = (props) => {
    return (
        <>
        <div className="container-fluid page-header py-5" style={props.bg?{backgroundImage:`url(${props.bg})`}:{}}>
            <div className="container py-5">
                <h1 className="display-3 text-white mb-3 animated slideInDown">{props.pageName}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a className="text-white" href="#">首页</a></li>
                        <li className="breadcrumb-item text-white active" aria-current="page">{props.pageName}</li>
                    </ol>
                </nav>
            </div>
        </div>
        </>
    )
}

export default PageHeader