import React from 'react'
import selectOur from "../../assets/20210224021407744.jpg"

const Idea = () => {
    return (
        <>
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                    <div className="container feature px-lg-0">
                        <div className="row g-0 mx-lg-0">
                            <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                                <div className="p-lg-5 ps-lg-0">
                                    <h6 className="text-primary">为什么选择我们？</h6>
                                    <h1 className="mb-4">让用户充分享受到低价电力供应的实惠，提高企业竞争力！</h1>
                                    <p className="mb-4 pb-2">致力于为客户提供高效且完善的服务！</p>
                                    <div className="row g-4">
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="btn-lg-square bg-primary rounded-circle">
                                                    <i className="fa fa-check text-white"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="mb-0">服务</p>
                                                    <h5 className="mb-0">质量</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="btn-lg-square bg-primary rounded-circle">
                                                    <i className="fa fa-user-check text-white"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="mb-0">业务</p>
                                                    <h5 className="mb-0">专家</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="btn-lg-square bg-primary rounded-circle">
                                                    <i className="fa fa-drafting-compass text-white"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="mb-0">免费</p>
                                                    <h5 className="mb-0">咨询</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="btn-lg-square bg-primary rounded-circle">
                                                    <i className="fa fa-headphones text-white"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="mb-0">支持</p>
                                                    <h5 className="mb-0">定制</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: "300px"}}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute img-fluid w-100 h-100" src={selectOur} style={{objectFit: 'cover'}} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Idea