import React from 'react'
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import { Tabs ,Space, Typography} from 'antd';
import historyNG from"../../../assets/history-bg.jpg"
const EventRecord = () => {
    return (
        <>
        <div style={{paddingLeft:30,paddingRight:30}}>
            <Tabs defaultActiveKey="1" items={[{
                key: '1',
                label: '大事记录',
                children:  
                <>
                    <div style={{backgroundImage:`url(${historyNG})`,width:"100%",height:110, backgroundSize:"cover",marginBottom:50,paddingLeft:40,paddingTop:20}}>
                        <p style={{fontSize:30}}>汇能售电成立于2017年</p>
                        <p>SINCE 2017</p>
                    </div>
                    <Timeline
                        mode="alternate"
                        items={[
                            {
                                children: 
                                <>
                                    <h5>2017年</h5>
                                    <p>2017年5月成立，是自治区发改委首批公示的十家售电公司之一</p>
                                </>,
                            },
                            {
                                children:
                                <>
                                    <h5>2018年</h5>
                                    <p>首次区内燃煤自备机组替代试点交易由我公司组织完成</p>
                                    <p>完成宁夏首次跨区跨省自备替代交易</p>
                                </>,
                                color: 'green',
                            },
                            {
                                dot: (
                                <ClockCircleOutlined
                                    style={{
                                    fontSize: '16px',
                                    }}
                                />
                                ),
                                children: 
                                            <>
                                                <h5>2019年</h5>
                                                <p>完成区内年度直购电交易30亿千瓦时</p>
                                                <p>完成区内自备替代交易19亿千瓦时</p>
                                                <p>完成新疆、甘肃外购电交易7亿千瓦时。</p>
                                            </>,
                            },
                            {
                                color: 'green',
                                children:   <>
                                                <h5>2020年</h5>
                                                <p>完成区内年度直购电交易40亿千瓦时</p>
                                                <p>完成区内自备替代交易23亿千瓦时</p>
                                            </>,
                            },
                            {
                                children:   <>
                                                <h5>2021年</h5>
                                                <p>完成区内年度直购电交易60亿千瓦时</p>
                                                <p>完成区内自备替代交易24亿千瓦时。</p>
                                            </>,
                            },
                            {
                                dot: (
                                <ClockCircleOutlined
                                    style={{
                                    fontSize: '16px',
                                    }}
                                />
                                ),
                                children:   <>
                                                <h5>2022年</h5>
                                                <p>完成区内年度直购电交易90亿千瓦时</p>
                                                <p>完成区内自备替代14.26亿千瓦时</p>
                                                <p>完成绿电交易3.04亿千瓦时</p>
                                                <p>完成新疆到宁夏外购电交易10亿千瓦时；</p>
                                                <p>完成宁夏到湖北外送电交易0.61亿千瓦时</p>
                                            </>,
                            },
                            {
                                children:   <>
                                                <h5>2023年</h5>
                                                <p>完成区内年度直购电交易200多亿千瓦时</p>
                                                <p>完成区内自备替代交易35.16亿千瓦时</p>
                                                <p>完成陕西自备替代交易0.27亿千瓦时</p>
                                                <p>完成宁夏到重庆外送电交易0.4亿千瓦时</p>
                                                <p>完成新疆到宁夏外购电交易2亿千瓦时</p>
                                            </>,
                            },
                        ]}
                    />
                </>
                ,
            }]}/>
        </div>
            
        </>
    )
}

export default EventRecord