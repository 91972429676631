import React from 'react'
import PageHeader from '../../components/PageHeader'
import {
    FileSearchOutlined,
    HomeOutlined
    } from '@ant-design/icons';
import { Menu } from 'antd';
import newsBanner from "../../assets/news-banner.jpg"
import { Outlet, useNavigate } from 'react-router-dom';
    function getItem(label, key, icon, children) {
        return {
        key,
        icon,
        children,
        label,
        };
    }
    const items = [
            getItem('公司新闻', '1', <HomeOutlined />),
            getItem('行业动态', '2', <FileSearchOutlined />)
        ];
const News = () => {
    const navigate = useNavigate();
    function menuChange({ key}){;
        switch(key){
            case '1':
                navigate("company");
                break;
            case '2':
                navigate("industry");
                break;
            default:
                break;
        }
    }
    return (
        <>
            <PageHeader pageName="新闻中心" bg={newsBanner}/>
            <div style={{backgroundColor:"rgb(246,247,248)",paddingBottom:50,paddingTop:50}}>
                {/* 左侧菜单 */}
                <div className='container' style={{display:"flex",flexWrap:"wrap",width:"100%"}} >
                    <Menu
                        style={{width: 256,height:110,padding:10,borderRadius:5,marginRight:20,marginBottom:"20px"}}
                        defaultSelectedKeys={['1']}
                        items={items}
                        onClick={menuChange}
                    />
                    <div className='pb-5' style={{backgroundColor:"#fff",width:"100%",borderRadius:5,width:"70%",minWidth:"360px"}}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default News