import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import { NavLink } from 'react-router-dom'
SwiperCore.use([Autoplay]);
const Carousel = () => {
    return (
        <>
        <Swiper
            loop
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{delay:3000}}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            >
            <SwiperSlide style={{margin:"auto"}}>
                <div style={{display:'flex',justifyContent:"center"}} className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-1.jpg'>">
                    <img className="img-fluid" src="img/carousel-1.jpg" width={"100%"} alt=""/>
                    <div className="owl-carousel-inner">
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-10 col-lg-8">
                                    <p className="display-4 text-white animated slideInDown">专注于能源管理</p>
                                    <p className="fs-10 fw-medium text-white mb-4 pb-3">让用户充分享受到低价电力供应的实惠，提高企业竞争力！</p>
                                    <NavLink className="btn btn-primary rounded-pill animated slideInLeft" to='/businessintroduction'>阅读更多</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                        <div style={{display:'flex',justifyContent:"center"}} className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-2.jpg'>">
                            <img className="img-fluid" src="img/carousel-2.jpg" width={"100%"}  alt=""/>
                            <div className="owl-carousel-inner">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-10 col-lg-8">
                                            <p className="display-5 text-white animated slideInDown">专注于能源管理</p>
                                            <p className="fs-10 fw-medium text-white mb-4 pb-3">让用户充分享受到低价电力供应的实惠，提高企业竞争力！</p>
                                            <NavLink className="btn btn-primary rounded-pill  animated slideInLeft" to='/businessintroduction'>阅读更多</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </SwiperSlide>
            <SwiperSlide>
                        <div style={{display:'flex',justifyContent:"center"}} className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-3.jpg'>">
                            <img className="img-fluid" src="img/carousel-3.jpg" width={"100%"} alt=""/>
                            <div className="owl-carousel-inner">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-10 col-lg-8">
                                            <p className="display-5 text-white animated slideInDown">专注于能源管理</p>
                                            <p className="fs-10 fw-medium text-white mb-4 pb-3">让用户充分享受到低价电力供应的实惠，提高企业竞争力！</p>
                                            <NavLink className="btn btn-primary rounded-pill  animated slideInLeft" to='/businessintroduction'>阅读更多</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </SwiperSlide>
        </Swiper>
        </>
    )
}
export default Carousel