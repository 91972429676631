import { useRoutes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import routes from './routes';
import Loading from"./components/Loading"


function App() {
  const element = useRoutes(routes);
  return (
    <div className="App">
      <Loading/>
      <Header/>
        {element}
      <Footer/>
    </div>
  );
}

export default App;
