import React, { useState,Fragment } from 'react'
import handSun from"../../assets/hand-sun.png"
import consult from"../../assets/consult.png"
import ironTower from"../../assets/iron-tower.png"
import ziXun from"../../assets/ziXun.png"
import { Button, Flex, Modal } from 'antd';
import {DoubleRightOutlined
    } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
const Business = () => {
    const [busines,setBusines] = useState([
        {
            id:1,
            title:"区内业务",
            image:handSun,
            iconName:"fa fa-solar-panel fa-3x",
            detail:"区内电力交易的宗旨主要是以做好品质，树立形象与口碑，服务好每一位用户，让用户充分享受到低价电力供应的实惠，提升企业竞争力！"
        },
        {
            id:2,
            title:"区外业务",
            image:ironTower,
            iconName:"fas fa-broadcast-tower fa-3x",
            detail:"跨区跨省业务持续打造我们公司资源优势，更好的为区内业务助力！"
        },
        {
            id:3,
            title:"咨询服务",
            image:consult,
            iconName:"fas fa-phone-alt fa-3x",
            detail:"公司有业内资深团队提供专业咨询服务，可为发电侧、售电侧、用户侧、虚拟电厂、储能、新能源等各类市场主体提供专业、优质、实时、高效的市场指导及相关服务，坚持以经济效益最优为原则，提高用户在电力市场的博弈能力。"
        },
    ]);
    const [quNeiYeWu,setQuNeiYeWu] = useState(false)
    const [quWaiYeWu,setQuWaiYeWu] = useState(false)
    const [ziXunFuWu,setZiXunFuWu] = useState(false)
    const goDetail = (id)=>{
        switch(id){
            case 1:
                setQuNeiYeWu(true);
                break;
            case 2:
                setQuWaiYeWu(true)
                break;
            case 3:
                setZiXunFuWu(true)
                break;
        }
    }
    return (
        <>
            <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                            <h6 className="text-primary">我们的业务</h6>
                            <h1 className="mb-4">我们是企业能源管理大师</h1>
                        </div>
                        <div className="row g-4">
                            {
                                busines.map((cur,index)=>{
                                    return <Fragment key={index} >
                                                <div  className="col-md-6 col-lg-4 wow fadeInUp"  data-wow-delay={(index+1)/10 + "s"}>
                                                    <div className="service-item rounded overflow-hidden">
                                                        <img className="img-fluid" src={cur.image} alt="" />
                                                        <div className="position-relative p-4 pt-0" style={{height:400}} onClick={()=>goDetail(cur.id)}>
                                                            <div className="service-icon">
                                                                <i className={cur.iconName}></i> 
                                                            
                                                            </div>
                                                            <h4 className="mb-3">{cur.title}</h4>
                                                            <p>{cur.detail}</p>
                                                            <NavLink style={{position:"absolute",bottom:30}} className="small fw-medium" href="">了解详情<DoubleRightOutlined /></NavLink>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </Fragment>
                                })
                            }
                        </div>
                        <Modal
                            title="区内业务"
                            centered
                            closeIcon={null}
                            open={quNeiYeWu}
                            onOk={() => setQuNeiYeWu(false)}
                            onCancel={() => setQuNeiYeWu(false)}
                            width={1000} 
                            footer={null}
                        >
                            <hr />
                            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                                <img className="img-fluid" src={handSun} alt="" />
                            </div>
                            
                            <hr />
                            <p>区内电力交易的宗旨主要是以做好品质，树立形象与口碑，服务好每一位用户，让用户充分享受到低价电力供应的实惠，提升企业竞争力！</p>
                            <p>随着现货市场的推进，汇能售电还不断优化电力市场交易服务模式与类型，利用自身技术优势为客户量身定制交易策略，辅助发电侧、售电侧现货咨询，形成一站式电力交易服务。累计为20余家火电企业、60余户中大型企业提供现货咨询、代理交易等相关服务，交易电量近200多亿千瓦时。</p>
                        </Modal>
                        <Modal
                            footer={null}
                            closeIcon={null}
                            title="区外业务"
                            centered
                            open={quWaiYeWu}
                            onOk={() => setQuWaiYeWu(false)}
                            onCancel={() => setQuWaiYeWu(false)}
                            width={1000}
                        >
                            <hr />
                            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                                <img className="img-fluid" src={consult} alt="" />
                            </div>
                            
                            <hr />
                            <p>跨区跨省业务持续打造我们公司资源优势，更好的为区内业务助力！</p>
                            <p>随着现货市场的推进，汇能售电还不断优化电力市场交易服务模式与类型，利用自身技术优势为客户量身定制交易策略，辅助发电侧、售电侧现货咨询，形成一站式电力交易服务。累计为20余家火电企业、60余户中大型企业提供现货咨询、代理交易等相关服务，交易电量近200多亿千瓦时。</p>
                        </Modal>
                        <Modal
                            closeIcon={null}
                            title="咨询服务"
                            centered
                            open={ziXunFuWu}
                            onOk={() => setZiXunFuWu(false)}
                            onCancel={() => setZiXunFuWu(false)}
                            width={1000}
                            footer={null}
                        >
                            <hr />
                            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                                <img className="img-fluid" src={ziXun} alt="" />
                            </div>
                            
                            <hr />
                            <p>公司有业内资深团队提供专业咨询服务，可为发电侧、售电侧、用户侧、虚拟电厂、储能、新能源等各类市场主体提供专业、优质、实时、高效的市场指导及相关服务，</p>
                            <p>坚持以经济效益最优为原则，提高用户在电力市场的博弈能力。</p>
                        </Modal>
                    </div>
                </div>
        </>

    )
}

export default Business