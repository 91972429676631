import React from 'react'
import aboutOur from "../../assets/20210224021424902.jpg"
import { NavLink } from 'react-router-dom'
const About = () => {
    return (
        <>
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                    <div className="container about px-lg-0">
                        <div className="row g-0 mx-lg-0">
                            <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{minHeight: "300px"}}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute img-fluid w-100 h-100" src={aboutOur} style={{objectFit: 'cover'}} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                                <div className="p-lg-5 pe-lg-0">
                                    <h6 className="text-primary">关于我们</h6>
                                    <h1 className="mb-4">8年的服务经验，竭尽全力让每一位客户都能用上经济环保的电能！</h1>
                                    <p>我们有数位电力交易专家，一对一为客户量身定制用电策略。</p>
                                    <p><i className="fa fa-check-circle text-primary me-3"></i>有效降低电费支出</p>、
                                    <NavLink className="btn btn-primary rounded-pill  mt-3" to='/about/companyprofile'>了解更多</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default About