import React from 'react'
import Carousel from '../../components/Carousel'
import Service from '../../components/Service'
import About from '../../components/About'
import Business from '../../components/Business'
import Idea from '../../components/Idea'
import BusinessDetail from '../../components/BusinessDetail'
import Team from '../../components/Team'
import Partner from '../../components/Partner'

const Home = () => {
    return (
        <>
            {/* 轮播图 */}
            <Carousel/>
            {/* 公司服务 */}
            <Service/>
            {/* 关于公司 */}
            <About/>
            {/* 公司业务 */}
            <Business/>
            {/* 公司理念 */}
            <Idea/>
            {/* 公司业务 */}
            <BusinessDetail/>   
            {/* 公司团队 */}
            <Team/>
            {/* 公司合作伙伴留言 */}
            {/* <Partner/> */}
        </>
    )
}

export default Home