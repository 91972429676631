import React, { useEffect, useRef } from 'react'

const Loading = () => {
    const myRef = useRef("");
    useEffect(() => { 
        // 删除加载层
        myRef.current.classList.remove("show")
        }, [])
    return (
        <>
            <div id="spinner" ref={myRef} className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default Loading