import { Navigate } from "react-router-dom";
import About from "../pages/About";
import Home from "../pages/Home";
import CompanyProfile from "../pages/About/CompanyProfile";
import OrgChart from "../pages/About/OrgChart";
import TeamIntroduce from "../pages/About/TeamIntroduce";
import EventRecord from "../pages/About/EventRecord";
import BusinessIntroduction from "../pages/BusinessIntroduction/idnex";
import News from "../pages/News";
import Company from "../pages/News/Company";
import IndustryTrends from "../pages/News/IndustryTrends";
import ConcatOur from "../pages/ConcatOur";
import JoinUs from "../pages/JoinUs";
import PersonIdea from "../pages/JoinUs/PersonIdea";
import Talent from "../pages/JoinUs/Talent";

const routes=[
    {
        path: '/',
        element: <Navigate to="/home"/>
    },
    {
        path:"/home",
        element: <Home/>
    },
    {
        path:"/about",
        element: <About/>,
        children:[
            {
                path:"companyprofile",
                element:<CompanyProfile/>
            },
            {
                path:"orgchart",
                element:<OrgChart/>
            },
            {
                path:"teamintroduce",
                element:<TeamIntroduce/>
            },
            {
                path:"eventrecord",
                element:<EventRecord/>
            }
        ]
    },
    {
        path:"/businessintroduction",
        element:<BusinessIntroduction/>
    },
    {
        path:"/news",
        element:<News/>,
        children:[
            {
                path:"company",
                element:<Company/>
            },
            {
                path:"industry",
                element:<IndustryTrends/>
            }
        ]
    },
    {
        path:"/concatour",
        element:<ConcatOur/>
    },
    {
        path:"/joinus",
        element:<JoinUs/>,
        children:[
            {
                path:"personidea",
                element:<PersonIdea/>
            },
            {
                path:"talent",
                element:<Talent/>
            }
        ]
    },
    {
        path:"*", // 将错误路由重定向到首页
        element:<Navigate to="/home"/>,
    }

]
export default routes;