import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from"react-router-dom"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
    <ConfigProvider theme={{ token: { colorPrimary: '#00b96b',fontSize:"16px"} }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ConfigProvider>
);

reportWebVitals();
