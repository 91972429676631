import React from 'react'
import "./index.css"
const DateBox = (params) => {
    return (
        <>
            <div className='dateWrap' >
                <div className='day'>{params.day}</div>
                <div className='year'>{params.year}</div>
            </div>
        </>
    )
}

export default DateBox